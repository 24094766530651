const NoPage = () => {
  return (
    <div className="h-64">
      <h1 className="text-9xl">404</h1>
      <p>Nie ma takiej strony spróbuj, gdzieś indziej.</p>
    </div>
  );
};

export default NoPage;
